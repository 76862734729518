@import "../../globals.scss";

.container {
  position: absolute;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-weight: 500;
}

.innerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #efefef;
  backdrop-filter: blur(40px);
}

.contentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  max-width: 1200px;
  width: 100%;
  @include phone {
    font-size: 12px;
    padding: 16px 12px;
  }
}

.trending {
  width: 120px;
  @include phone {
    width: 56px;
    text-align: center;
  }
}

.info {
  width: 80%;
  margin: 0 24px;
  @include phone {
    width: 70%;
    margin: 0 12px;
  }
  p {
    text-align: center;
  }
}

.right {
  display: flex;
  align-items: center;
  gap: 48px;
  justify-content: space-between;
  .knowMore {
    width: 120px;
    cursor: pointer;
    color: #0518BC;
    &:hover {
      text-decoration: underline;
    }
    @include phone {
      display: none;
    }
  }
  .close {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
