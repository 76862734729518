@import "../../globals.scss";

.container {
  padding: 63px 0 40px 0;
  h2 {
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    margin: auto;
    @include phone {
      font-size: 26px;
      line-height: 38px;
    }
  }
}

.helped {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 42px;
  span {
    font-weight: 600;
  }
  margin: 50px 0;
  @include phone {
    font-size: 12px;
    line-height: 20px;
  }

  .trademarkia {
    width: 200px;
    display: inline-block;
    margin: 0 10px;
  }
}

.topDesc {
  font-weight: 500;
  font-size: 16px;
  margin: 30px 0 50px 0;
  line-height: 35px;
  text-align: center;
  @include phone {
    font-size: 12px;
    line-height: 22px;
    margin: 0 0 26px;
  }
}

.cards {
  display: flex;
  // @include Gap(0 30px);
  gap: 30px;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
}
