.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerContainer {
  width: 100%;
  max-width: 1240px;
  padding: 0 20px;
}
