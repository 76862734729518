$blue: #3c4ff8;

@mixin phone-small {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 990px) {
    @content;
  }
}

@mixin tablet-large {
  @media (max-width: 1300px) {
    @content;
  }
}

@mixin pc-small {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin Gap($gap, $imp: false) {
  @if ($imp) {
    & > * {
      margin: #{$gap * 0.5} !important;
    }
    margin: #{$gap * -0.5} !important;
  } @else {
    & > * {
      margin: #{$gap * 0.5};
    }
    margin: #{$gap * -0.5};
  }
}
