@import "../globals.scss";

.container {
  overflow-x: hidden;
  display: grid;
  place-items: center;
  background-image: url("../../assets/gradient_bg.svg");
  background-position: top center;
  background-repeat: no-repeat;
  padding: 80px 0;
  width: 100vw;
  position: relative;
  overflow-y: hidden;
  background-size: 100%;
  // min-height: 115vh;

  @include phone {
    background-size: 180%;
  }

  @include phone-small {
    background-size: 400%;
  }

  .children {
    display: flex;
  }
}

.innerContainer {
  width: 100vw;
}

.blur {
  position: absolute;
  left: 0px;
  top: 0;
  pointer-events: none;
  // opacity: 0.7;
  z-index: -1;
  // width: 200vw;
  img {
    width: 100vw !important;
    // height: 100% !important;
    // max-width: unset !important;
    // max-height: unset !important;
    // object-fit: cover;
  }
  // @include phone {
  //   width: 150vw !important;
  //   transform: translate(-30%, -40%) scale(2);
  //   top: 50%;
  // }
}

.children {
  width: 100%;
  margin-top: 80px;

  @include phone {
    margin-top: 36px;
  }
}
