@import "../../globals.scss";

.outerContainer {
  margin-top: 0 !important;
}

.container {
  margin-top: 120px;
  &.noHeadline {
    margin-top: 65px;
  }
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  gap: 40px;
  width: 100%;
  @include phone {
    gap: 20px;
  }
  h2 {
    font-weight: 600;
    font-size: 38px;
    line-height: 56px;
    max-width: 367px;
    @include phone {
      font-size: 26px;
      line-height: 38px;
      text-align: center;
      margin: auto;
    }
  }
}

.top {
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 100px;
}

.left {
  @include phone {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    font-weight: 600;
    font-size: 40px;
    line-height: 56px;
    max-width: 544px;
    width: 100%;
    @include phone {
      text-align: center;
      font-size: 24px;
      line-height: 42px;
      max-width: unset;
    }
  }
  p.price {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-top: 32px;
    margin-bottom: 44px;
    max-width: 386px;
    @include phone {
      text-align: center;
      font-size: 16px;
      line-height: 28px;
    }
  }
  p.tags {
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    margin-top: 28px;
    margin-bottom: 29px;
    @include phone {
      text-align: center;
      font-size: 11px;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }
  button {
    background-color: #3c4ff8;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    padding: 17.5px 120px;
    border-radius: 7.5px;
    border: none;
    transition: 0.2s;
    margin-right: auto;
		&:hover {
			background-color: #021bff;
		}
    @include phone {
      margin: auto;
      padding: 14px;
      width: 100%;
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.right {
  flex: 1;
  @include phone {
    display: none;
  }
}
.box {
  position: relative;
  .play {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.2s;
  }
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 80px;
  gap: 50px;
  @include phone {
    text-align: center;
    gap: 30px;
    margin-top: 0;
  }
  .text {
    text-align: center;
    width: 100%;
    @include phone {
      margin-top: 0px;
    }

    p {
      &:nth-child(1) {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 8px;
        @include phone {
          font-size: 12px;
          line-height: 14px;
        }
      }
      &:nth-child(2) {
        font-weight: 600;
        font-size: 22px;
        @include phone {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}

.platforms {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-width: 500px;
  max-width: 1000px;
  width: 100%;
  margin: auto;
  @include phone {
    min-width: unset;
    // flex-direction: column;
    gap: 20px;
    & > * {
      gap: 0;
      max-width: 25vw !important;
    }
  }
  img {
    object-fit: contain;
  }
}

.mobImg {
  display: none;
  margin-bottom: 25px;
  @include phone {
    display: grid;
    place-items: center;
  }
}
