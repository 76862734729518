@import "../../globals.scss";

.grad {
  border-radius: 15px;
  // max-width: 300px;
  // flex: 1 1 260px;
  margin: 30px;
  width: 42.5%;
  @include phone {
    width: 100%;
  }
}

.container {
  // background: #0b0c17;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  .icon {
    position: relative;
    border-radius: 15px;
    border: 2px solid;
    cursor: pointer;
    height: 115px;
    width: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    background: #02030c;
    background-clip: padding-box;
    border: solid 2px transparent;
    @include phone {
      // margin-right: 15px;
      height: 75px;
      width: 100px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -2px;
      border-radius: inherit;
    }
    &:hover::before {
      background: linear-gradient(213.13deg, #438BFC 3.65%, #44EAA4 99.72%);
    }
  }
  .remint {
    padding: 12.5px;
  }
  .details {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include phone {
      width: 100%;
    }
    h3 {
      font-weight: 600;
      font-size: 26px;
      line-height: 60px;
      @include phone {
        font-size: 20px;
        line-height: 45px;
      }
    }
    p {
      font-size: 15px;
      line-height: 25px;
      @include phone {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
